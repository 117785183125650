import * as React from "react"
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Hero from '../components/Hero'
import ProblemSection from '../components/ProblemSection'
import SolutionStatement from "../components/SolutionSection";
import UseCaseSection from "../components/UseCaseSection";
import IntegrationSection from "../components/IntegrationSection";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";

import HeroImage from '../images/hero-image.svg'

import '../styles/base.scss'

const seoDesc = 'Clime connects your team\'s product information to make sure every team member has access to the entire picture of what your building and why you\'re building it.';
const seoTitle = 'Clime - Reconnect your siloed product information'

const IndexPage = () => {
  return (
    <main>
        <Helmet>
            <title>{seoTitle}</title>
            <meta name="description" content={seoDesc} />
            <meta name="image" content={HeroImage} />
            <meta property="og:url" content="https://clime-app.com" />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:description" content={seoDesc} />
            <meta property="og:image" content={HeroImage} />
            <meta property="twitter:title" content={seoTitle} />
            <meta property="twitter:description" content={seoDesc} />
            <meta property="twitter:image" content={HeroImage} />
        </Helmet>
      <title>Home Page</title>
      <Header />
      <Hero />
      <ProblemSection />
      <SolutionStatement />
      <UseCaseSection />
      <IntegrationSection />
      <CallToAction />
      <Footer />
    </main>
  )
}

export default IndexPage
