import React, { useState } from 'react'
import UseCaseMap from "./UseCaseMap";
import { useCases } from "../common";
import '../styles/useCase.scss'

const useCaseCardData = [
  {
    key: useCases.product,
    title: 'Hannah - Product',
    body: 'Is researching how to improve an existing feature of the product',
    subTitle: 'Clime makes it easier to understand customer pain points',
    explanation: 'Hannah performs a query to find support tickets and workarounds related to the released feature which allows her to analyse where customers are experiencing issues with the product and create a set of questions to ask customer support staff and user research groups.'
  },
  {
    key: useCases.design,
    title: 'Dieter - Design',
    body: 'Is reviewing the implementation of the design system his team built',
    subTitle: 'Clime makes it easier to improve your UI and UX',
    explanation: 'Dieter performs a query to find defects and support tickets linked to user stories that use the components he\'s reviewing. This allows him to identify areas where the applications UI has confused customers and he can create new patterns to improve the user experience.'
  },
  {
    key: useCases.analysis,
    title: 'Jeff - Analysis',
    body: 'Is looking to identify risks associated with a User Story on the backlog',
    subTitle: 'Clime makes it easier to identify development risk early',
    explanation: 'Jeff performs a query to find technical debt, defects and tests related to the code for User Stories in the same feature. This allows him to identify areas where the development team may encounter issues and work through these risks in a 3 amigos session.'
  },
  {
    key: useCases.development,
    title: 'Ada - Development',
    body: 'Has picked up a User Story to work on & wants to know impact of their change',
    subTitle: 'Clime makes it easier to continuously improve your codebase',
    explanation: 'Ada performs a query to find tests, defects, design system components & technical debt related to the code and the user story they are working on. This allows them to see other areas they can update in order to continually improve the codebase.'
  },
  {
    key: useCases.operation,
    title: 'Julie - Operations',
    body: 'Is creating support material for a new feature due to be released',
    subTitle: 'Clime makes it easier to create support documentation',
    explanation: 'Julie performs a query to find defects & mockups related to the user stories being released and the workarounds for the defects. This allows her to create a more complete support document for the feature and gives questions to ask the team to understand more about the feature.'
  }
]

function UseCaseCard({ title = 'Title', body = 'Body', onHover = () => {} }) {
  return (
    <button className='use-case-card' onMouseOver={onHover} onClick={onHover} onFocus={onHover}>
      <div className='use-case-card-copy'>
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
    </button>
  )
}

export default function UseCaseSection() {
  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const useCaseCards = useCaseCardData.map((useCase, index) => {
    return (
      <UseCaseCard
        title={useCase.title}
        body={useCase.body}
        onHover={() => setActiveCardIndex(index)}
      />
    )
  })
  const activeUseCase = useCaseCardData[activeCardIndex]
  return (
    <section>
      <div className='section-title'>
        <h2>How Clime can help you</h2>
        <p className='subtitle'>Discover how Clime can improve your team's access to the information they need</p>
      </div>
      <div className='col-3-1-2'>
        <div className='use-case-cards'>
          {useCaseCards}
        </div>
        <div className='use-case-visualisation'>
          <div className='use-case-graph'>
            <UseCaseMap activeUseCase={activeUseCase.key} />
          </div>
          <div className='use-case-explanation'>
            <h3>{activeUseCase.subTitle}</h3>
            <p>{activeUseCase.explanation}</p>
          </div>
        </div>
      </div>
    </section>
  )
}