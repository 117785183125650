import React from 'react'
import '../styles/button.scss'

function getColourClass(colour) {
    switch(colour) {
        case 'primary':
            return 'colour-primary'
        default:
            return 'colour-default'
    }
}

function getSizeClass(size) {
    switch(size) {
        case 'large':
            return 'size-large'
        default:
            return 'size-normal'
    }
}

function getButtonClass(colour, size) {
    const colourClass = getColourClass(colour)
    const sizeClass = getSizeClass(size)
    return `button ${sizeClass} ${colourClass}`
}

export default function Button({ url, children, colour = 'primary', size = 'normal' }) {
    const buttonClass = getButtonClass(colour, size)
    return (
        <a href={url} target='_blank' rel='noreferrer' className={buttonClass}>{children}</a>
    )
}