import React from 'react'
import SolutionMap from '../images/solution-map.svg'
import SolutionQuery from '../images/solution-query.svg'
import SolutionQuestion from '../images/solution-question.svg'

export default function SolutionStatement() {
  return (
    <section>
      <div className='section-title'>
        <h2>Connect your product information</h2>
        <p className='subtitle'>Clime helps you reconnect the information across your team's specialised tools</p>
      </div>
      <div className='col-3'>
        <div>
          <div>
            <img className='big-image' src={SolutionMap} alt='1 graph showing thw output of 3 different teams and how the outputs of those teams are connected' />
          </div>
          <div className='copy'>
            <h3>Map the relationships between your product resources</h3>
            <p>Use Clime's integrations to pull in resources from your tools and define the relationship between each item to build a product knowledge graph can query to understand your product better.</p>
          </div>
        </div>
        <div>
          <div>
            <img src={SolutionQuery} alt='A query being executed against the product knowledge graph showing how the user can get to designs for feature from the code that implements it via the relationship they have with the user story' />
          </div>
          <div className='copy'>
            <h3>Query your product's knowledge graph in your favourite tools</h3>
            <p>Use Clime's plugins to access your product's knowledge graph while working in your tools and build a better understanding of the resource your working on within the context of your product.</p>
          </div>
        </div>
        <div>
          <div>
            <img src={SolutionQuestion} alt='A person using the understanding gained from querying the product knowledge grpah to ask a question' />
          </div>
          <div className='copy'>
            <h3>Make better informed decisions about how to build your product</h3>
            <p>Discover the full picture surrounding your product's features and use that enhanced understanding to ask more questions, explore more options and make better informed decisions.</p>
          </div>
        </div>
      </div>
    </section>
  )
}