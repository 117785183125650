import React from 'react'
import Button from './Button'
import HeroImage from '../images/hero-image.svg'
import '../styles/hero.scss'
import {signupFormURL} from "../config";

export default function Hero() {
    return (
        <section className='col-2 hero'>
            <div>
                <h1>Still discovering important product requirements half-way through development?</h1>
                <p className='subtitle'>Clime connects your team's product information to make sure every team member has access to the entire picture of what your building and why you're building it.</p>
                <p className='cta'>
                    <Button url={signupFormURL} size='large'>Register your interest</Button>
                </p>
            </div>
            <div>
                <img src={HeroImage} alt='A person sitting at a laptop with a graph of information resources in the background' />
            </div>
        </section>
    )
}