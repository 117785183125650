import React from 'react'
import Button from "./Button";
import { signupFormURL} from "../config";

export default function CallToAction() {
  return (
    <section className='section-title'>
      <h2>Sign up for more information</h2>
      <p className='subtitle'>Clime is currently under active development. Join our mailing list to gain access to the Clime alpha and product updates.</p>
      <Button url={signupFormURL} size='large' colour='black'>Register your interest</Button>
    </section>
  )
}