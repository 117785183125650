import React from 'react'
import '../styles/integration.scss'

export default function IntegrationSection() {
  return (
    <section>
      <div className='section-title'>
        <h2>Clime works with your favourite tools</h2>
        <p className='subtitle'>Install Clime integrations into your tools to access your knowledge graph at any time</p>
      </div>
      <div className='col-4'>
        <div>
          <h3>Product Management</h3>
          <ul>
            <li>Miro</li>
            <li>Powerpoint</li>
            <li>Visio</li>
            <li>Draw.io</li>
            <li>Jira</li>
            <li>Taiga</li>
            <li>Confluence</li>
            <li>Trello</li>
          </ul>
        </div>
        <div>
          <h3>Design</h3>
          <ul>
            <li>Figma</li>
            <li>Adobe XD</li>
            <li>Sketch</li>
            <li>Marvel</li>
            <li>Zeppelin</li>
          </ul>
        </div>
        <div>
          <h3>Development</h3>
          <ul>
            <li>Github</li>
            <li>Gitlab</li>
            <li>Visual Studio Code</li>
            <li>Jetbrains IDEs</li>
            <li>Vim</li>
            <li>Zephyr</li>
            <li>X-Ray</li>
            <li>Q-Test</li>
            <li>Spira test</li>
          </ul>
        </div>
        <div>
          <h3>Support</h3>
          <ul>
            <li>Jira</li>
            <li>Datadog</li>
            <li>Splunk</li>
            <li>Sentry</li>
          </ul>
        </div>
      </div>
      <div className='section-title cta'>
        <p className='subtitle'>Don't see a tool you're looking for? Contact sales to request an integration</p>
      </div>
    </section>
  )
}