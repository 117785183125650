import React from 'react'
import ProblemImage from '../images/problem.svg'

export default function ProblemSection() {
  return (
    <section>
      <div className='section-title'>
        <h2>Your product information is disconnected</h2>
        <p className='subtitle'>The specialised tools your team uses creates silos of information</p>
      </div>
      <div className='col-2'>
        <div>
          <img className='big-image' src={ProblemImage} alt='3 separate graphs showing the output of 3 different teams, the graphs are disconnected from each other' />
        </div>
        <div>
          <h3>Disconnected information slows your team down</h3>
          <p>Your team's ability to build your product is highly dependent on their ability to understand what they need to do to build it and the value their giving your customers.</p>
          <p>Information hidden in tools prevents your team from getting access to the resources they need to build this understanding, resulting in longer development times, bugs and missed market fit.</p>
        </div>
      </div>
    </section>
  )
}