import React from 'react'
import Button from './Button'
import Logo from '../images/logo.svg'
import {signupFormURL} from "../config";

export default function Header() {
    return (
        <header className='col-2'>
            <div>
                <img src={Logo} alt='Clime' />
            </div>
            <div className='float-right'>
                <Button url={signupFormURL}>Register your interest</Button>
            </div>
        </header>
    )
}